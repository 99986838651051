import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgBoardingNotification from "../images/gfx/school-bus-boarding-notification.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"
import Nav from "../components/nav"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="School bus boarding notifications: Gimmick or lifesaver?" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">School bus boarding notifications: Gimmick or lifesaver?</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">School bus boarding notifications: Gimmick or lifesaver?</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>Demand for boarding notifications on school buses from parents and schools is booming, but is it just a gimmick or does it provide genuine value to users?</p>
                    <p>Based on surveys of parents using boarding notifications with ShuttleID, and lots of conversations with schools and operators since 2019, we've debunked some common myths about boarding notifications.</p>
                    <p>If you've been thinking about introducing boarding notifications on your school bus read this first!</p>

                    <p><img src={imgBoardingNotification} alt="" /></p>
                  </div>
                  <div className='article-inner'>
                    <h3 id="part-1">❌ "All parents want them!"</h3>
                    <p>Not <em>all</em> parents want them. We've seen this firsthand with parent opt-in rates using boarding notifications on the ShuttleID platform. Lots of circumstances can contribute to your opt-in rate such as distanced travelled, punctuality or safety record of the operator and of course how relaxed a parenting style is.</p>
                    <p>Whilst not every parent wants boarding nofitications, the parents that do want it, <em>really</em> want it.</p>
                    <p>Offering an extra safety feature might just be the ticket to securing extra passengers on your school bus that would have otherwise been lost to the parent driving, or a parent instead opting for a closer school.</p>
                    <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15, fontWeight: 'bold' }}>"It’s lovely to know exactly when the bus comes, that she’s on board and safe."<span style={{ display: 'block', fontWeight: 'normal' }}>Cara B, parent</span></p>
                    {/* <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>Drivers are less distracted as the job of checking passes is largely removed. Passengers instead wave their ticket or phone at a fixed mobile device to board. This allows drivers to focus on the road and wellbeing of passengers. </p> */}

                    <h3 id="part-2">❌ "Boarding notifications are only for overprotective parents"</h3>
                    <p>Whilst safety is a key benefit for boarding notifications, we've found that boarding notifications are beneficial for practical families too. The notifications help parents plan for when they need to pick up their child from the bus stop or for when they can expect their child to reach home.</p>
                    <p>Without boarding notifications and vehicle tracking, parents are completely reliant on their children to keep them up to date - which we all know doesn't happen!</p>
                    <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15, fontWeight: 'bold' }}>"It helps us to plan when to pick up. Also it is reassuring to know she's on the bus."<span style={{ display: 'block', fontWeight: 'normal' }}>Cassara J, parent</span></p>

                    <h3 id="part-3">❌ "Parents will get fed up of them"</h3>
                    <p>Busy parents are juggling a lot and more notifications can add to the general noise and stress of life. This is why it's important to ensure that boarding notifications are offered as part of an opt-in process.</p>
                    <p>ShuttleID allows parents to opt-in for boarding notifications, as well as being able to opt-out again at any point.</p>
                    <p>Some parents may only want to enable this feature for the start of the year, to gain confidence the bus service is safe as their child gets used to travelling to a new place. Many parents will continue to get value from being able to stay informed all year round.</p>
                    <p>What is important is giving the control to the parents for them to decide.</p>
                    <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15, fontWeight: 'bold' }}>"I would love boarding notifications to continue next September."<span style={{ display: 'block', fontWeight: 'normal' }}>Michael P, parent</span></p>

                    <h3 id="part-4">❌ "Parents can just use their own app to track their child"</h3>
                    <p>It's true that more options have become available to parents to help safeguard their children, including the use of apps such as "Find My iPhone". But only after speaking with parents trialling boarding notifications with ShuttleID did we realise that there is still great value in the feature.</p>
                    <p>We heard from parents that with the loss of battery, signal or data allowance, parents were frequently unable to track their child using their own measures. Boarding notifications and live vehicle tracking from our platform gave parents a consistently clear picture of what was happening with their child, and their personal app tracking gave them an idea of location for movements beyond the school bus.</p>
                    <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15, fontWeight: 'bold' }}>"Although I can see my daughters location from her mobile phone, in some cases due to battery or signal issues it does not always show, so having this additional confirmation has been greatly appreciated."<span style={{ display: 'block', fontWeight: 'normal' }}>Michael P, parent</span></p>

                    {/* <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15, fontWeight: 'bold' }}><span style={{ display: 'block', fontWeight: 'normal' }}>How ShuttleID helps</span>Drivers are less distracted as the job of checking passes is largely removed. Passengers instead wave their ticket or phone at a fixed mobile device to board. This allows drivers to focus on the road and wellbeing of passengers. </p> */}

                    <h3 id="part-5">❌ "Parents need notifications by SMS"</h3>
                    <p>SMS is one way to get a message across to a parent's phone but they are typically not well suited for boarding notifications.</p>
                    <p>If every message is sent by SMS, it dilutes the effectiveness for when you really need to get an critical message out to parents and to make sure it is seen.</p>
                    <p>SMS is a costly medium and a poor choice for boarding notifications. As an example, with each individual message sent costing a few pence, it would cost around £7,800 annually to send boarding notifications twice per day by SMS to 500 parents. Other notification protocols cost a fraction of this.</p>
                    <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15, fontWeight: 'bold' }}>"Peace of mind that the bus is running on time and that my children are on it!"<span style={{ display: 'block', fontWeight: 'normal' }}>Lucy W, parent</span></p>

                    <h3 id="part-6">❌ "We need an app to have boarding notifications"</h3>
                    <p>You don't necessarily need to have an app just to be able to offer boarding notifications. There are other ways to get native notifications to users without the complication of having users install yet another app, or the significant expense of having to build and maintain a bespoke app for different operating systems.</p>
                    <p>Email is a well understood and cost-effective protocol that works great on mobile devices, and they even offer native push notifications on phones.</p>
                    <p>Boarding notifications by email and critical service alerts by SMS is an excellent way to divide the urgency of routine vs important.</p>
                    <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15, fontWeight: 'bold' }}>"It is good to check that things are running smoothly."<span style={{ display: 'block', fontWeight: 'normal' }}>Rebecca W, parent</span></p>

                    <h3 id="part-7">❌ "Parents need notifications when they leave the bus too"</h3>
                    <p>Speaking from experience, off-boarding notifications end up often being more hassle than they're worth in a typical school bus environment. The reason is compliance.</p>
                    <p>Whilst it's easy for a driver to refuse a passenger to board if they don't present a ticket, it's impossible for a driver to stop school children leaving the bus without scanning their ticket again. School children want to get off the bus as quick as possible, and equally, the driver is under pressure to not add any extra delay onto the journey time.</p>
                    <p>As there is no incentive for school children to scan their ticket when alighting, it's difficult to enforce. However, a combination of boarding notifications and live vehicle tracking typically gives parents all the assurance they need.</p>
                    <p>In the event a child got off at a stop they shouldn't, parents will still need other tools/measures to help track their child outside of a school bus environment, and this is where personal tracking apps and other technology can be useful.</p>
                    <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15, fontWeight: 'bold' }}>"Just good to know they are on the bus."<span style={{ display: 'block', fontWeight: 'normal' }}>Joanne S, parent</span></p>

                    <h3 id="part-8">❌ "The child has to have a phone for it to work"</h3>
                    <p>A misconception amongst some parents is that their child must have a phone in order to benefit from boarding notifications.</p>
                    <p>Whilst it is true that most school children prefer to have their school bus ticket on their phone, ShuttleID tickets can also be printed out.</p>
                    <p>Whether the pass is on a phone or printed, it is scanned by the ticket scanner, and our platform sends boarding notifications to the parent in real-time.</p>
                    <p style={{ background: 'rgb(195 218 242)', color: 'rgb(3 53 103)', marginBottom: 40, padding: 15, fontWeight: 'bold' }}>"My daughter is year 3 so it’s good to have peace of mind she’s on the bus safe."<span style={{ display: 'block', fontWeight: 'normal' }}>Olivia F, parent</span></p>

                    <h2>Summary</h2>
                    <p>If you've never used boarding notifications before on your school bus we hope our article has helped given you some insight on how they work and what value they actually bring.</p>
                    <p>Boarding notifications will provide great value to a reasonable number of parents using your bus service and can even help convince safety conscience parents that your school bus is safe to use.</p>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>Can ShuttleID help me?</span>Yes! Boarding notifications are included for FREE with our LIVE add-on. The benefits of ShuttleID not only makes running your school bus easier to manage but importantly it makes your customers happy. Our technology is designed to be simple, affordable and keeps you in full control. Get in touch today on 0333 344 9868 for more information or book your <Link style={{ fontWeight: 'bold', color: 'white' }} to="/book-demo/">free online demo</Link>.</p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID; the affordable, digital bus pass and ticketing system that keeps you in control.</p>
                        <p style={{ marginBottom: 10 }}>During a 15 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home-to-school first hand, leading to the launch of ShuttleID in 2019.</p>
                        <p style={{ margin: 0 }}>Email: <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage